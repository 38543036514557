function submitQuiz() {
    // Get the selected value for each question
    var q1 = document.querySelector('input[name="q1"]:checked').value;
    var q2 = document.querySelector('input[name="q2"]:checked').value;
    var q3 = document.querySelector('input[name="q3"]:checked').value;
    var q4 = document.querySelector('input[name="q4"]:checked').value;
    var q5 = document.querySelector('input[name="q5"]:checked').value;

    // Calculate the user's score
    var score = parseInt(q1) + parseInt(q2) + parseInt(q3) + parseInt(q4) + parseInt(q5);

    // Determine the user's money personality
    var result;
    if (score <= 7) {
        result = `<h2>You’re a spontaneous splurger</h2><p>You prefer to be spontaneous with your spending. Sticking to a budget just isn’t for you. You’re likely to splash out if you know the quality or sustainability of an item will be worth it.</p><h3>Learned something new about yourself?</h3><p>Did you get the result you expected, or did something surprise you? Are you confident in your financial decision-making, or are there things you’d like to change?</p><p>CAP offers a range of services, including face-to-face money coaching. Through a series of workshops held at a local church near you, you’ll learn budgeting skills and a simple system for managing your money, alongside like-minded people in a group setting.</p><p>Money coaching will equip you to understand your personal finances better, build your own budget, and stay in control now and into the future.</p><div class='matrix matrix--call-to-action'><div class='container'><div class='container-inner'><div class='cta-block lc--nbm'><h2 class='cta-block__heading heading-lg'>Find your local money coaching course</h2> <a class='cta-block__button button button--green' href='https://capuk.org/get-help/cap-money-course'>Find out more <svg class='cta-block__arrow' width='11' height='18' viewBox='0 0 11 18' fill='none"><path d='M0 16l7-7-7-7 2-2 9 9-9 9-2-2z' fill='currentColor'></path></svg></a></div></div></div></div>`;
    } else if (score <= 12) {
        result = `<h2>You’re an intermittent indulger</h2><p>You’re not really one to budget, and you’re likely to buy something based on the quality, even if it costs a bit more. There’s a limit to your spending, though, and you might browse and see if there are any good deals around before committing to buying something that’s really pricey.</p><h3>Learned something new about yourself?</h3><p>Did you get the result you expected, or did something surprise you? Are you confident in your financial decision-making, or are there things you’d like to change?</p><p>CAP offers a range of services, including face-to-face money coaching. Through a series of workshops held at a local church near you, you’ll learn budgeting skills and a simple system for managing your money, alongside like-minded people in a group setting.</p><p>Money coaching will equip you to understand your personal finances better, build your own budget, and stay in control now and into the future.</p><div class='matrix matrix--call-to-action'><div class='container'><div class='container-inner'><div class='cta-block lc--nbm'><h2 class='cta-block__heading heading-lg'>Find your local money coaching course</h2> <a class='cta-block__button button button--green' href='https://capuk.org/get-help/cap-money-course'>Find out more <svg class='cta-block__arrow' width='11' height='18' viewBox='0 0 11 18' fill='none"><path d='M0 16l7-7-7-7 2-2 9 9-9 9-2-2z' fill='currentColor'></path></svg></a></div></div></div></div>`;
    } else if (score <= 17) {
        result = `<h2>You’re a bargain browser</h2><p>You like to budget, and are aware of what you’re spending. You don’t like to spend more than you need to, but you might treat yourself once in a while or pay a bit more for something you know will definitely last.</p><h3>Learned something new about yourself?</h3><p>Did you get the result you expected, or did something surprise you? Are you confident in your financial decision-making, or are there things you’d like to change?</p><p>CAP offers a range of services, including face-to-face money coaching. Through a series of workshops held at a local church near you, you’ll learn budgeting skills and a simple system for managing your money, alongside like-minded people in a group setting.</p><p>Money coaching will equip you to understand your personal finances better, build your own budget, and stay in control now and into the future.</p><div class='matrix matrix--call-to-action'><div class='container'><div class='container-inner'><div class='cta-block lc--nbm'><h2 class='cta-block__heading heading-lg'>Find your local money coaching course</h2> <a class='cta-block__button button button--green' href='https://capuk.org/get-help/cap-money-course'>Find out more <svg class='cta-block__arrow' width='11' height='18' viewBox='0 0 11 18' fill='none"><path d='M0 16l7-7-7-7 2-2 9 9-9 9-2-2z' fill='currentColor'></path></svg></a></div></div></div></div>`;
    } else {
        result = `<h2>You’re a stringent saver</h2><p>You’re more likely to put cost above all else, and stick to a strict budget. You consider any purchases before spending anything, and will try and find the cheapest deal around before buying.</p><h3>Learned something new about yourself?</h3><p>Did you get the result you expected, or did something surprise you? Are you confident in your financial decision-making, or are there things you’d like to change?</p><p>CAP offers a range of services, including face-to-face money coaching. Through a series of workshops held at a local church near you, you’ll learn budgeting skills and a simple system for managing your money, alongside like-minded people in a group setting.</p><p>Money coaching will equip you to understand your personal finances better, build your own budget, and stay in control now and into the future.</p><div class='matrix matrix--call-to-action'><div class='container'><div class='container-inner'><div class='cta-block lc--nbm'><h2 class='cta-block__heading heading-lg'>Find your local money coaching course</h2> <a class='cta-block__button button button--green' href='https://capuk.org/get-help/cap-money-course'>Find out more <svg class='cta-block__arrow' width='11' height='18' viewBox='0 0 11 18' fill='none"><path d='M0 16l7-7-7-7 2-2 9 9-9 9-2-2z' fill='currentColor'></path></svg></a></div></div></div></div>`;
    }

    // Display the result
    document.getElementById("result").innerHTML = result;
}