// handle links starts with '#' only
$(document).on('click', 'a[href^="#"]', function(e) {
  // target element id
  var id = $(this).attr('href');

  // If the href is JUST a hash (i.e. has no target), then eject ourselves from this behaviour
  if(id == "#") {
    return;
  }

  // target element
  var $id = $(id);
  if ($id.length === 0) {
      return;
  }
  // prevent standard hash navigation (avoid blinking in IE)
  e.preventDefault();
  // top position relative to the document
  var pos = $id.offset().top;
  // animated top scrolling
  $('body, html').animate({scrollTop: pos});
});