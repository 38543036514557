// Try and strip any text formatting that might come in from Form Assembly
$(".form-assembly label span").each(function() {
  $(this).attr("style", "");
});

// Populate some hidden UTM tracking inputs
if (typeof utmSource !== 'undefined') {
  $("input#tfa_251").val(utmSource);
}

if (typeof utmCampaign !== 'undefined') {
  $("input#tfa_253").val(utmCampaign);
}

if (typeof utmMedium !== 'undefined') {
  $("input#tfa_255").val(utmMedium);
}
