var donateForms = document.querySelectorAll(".donation-widget-options-form");

if (donateForms != 'undefined' && donateForms != null) {
  donateForms.forEach(formAmount);

  function formAmount(form) {
    const radioInputs = form.querySelectorAll(".donate-amount-option");
    const amountInput = form.querySelector(".donate-amount");
    const requirePhoneNumberInput = form.querySelector("#requirePhoneNumber");
    const minAmount = Math.floor(amountInput.min);
    const maxAmount = Math.floor(amountInput.max);
    const minAmountMessage = form.querySelector(".donate-widget__amount-message--min");
    const maxAmountMessage = form.querySelector(".donate-widget__amount-message--max");
    const submitButton = form.querySelector(".donate-widget__button");

    // update form amount field
    if (radioInputs != 'undefined' && radioInputs != null) {
      for (var i = 0; i < radioInputs.length; i++) {
        const radioInput = radioInputs[i];

        amountUpdate(radioInput, amountInput);
      }
    }

    // min/max donation amount
    amountInput.onchange = function() {
      var inputAmount = Math.floor(amountInput.value);
      // minimum amount
      if (inputAmount < minAmount) {
        minAmountMessage.style.display = "block";
        maxAmountMessage.style.display = "none";
        submitButton.disabled = true;
        // console.log("too small");
      }
      // maximum amount
      else if (inputAmount > maxAmount) {
        maxAmountMessage.style.display = "block";
        minAmountMessage.style.display = "none";
        submitButton.disabled = true;
        // console.log("too big");
      }
      // all other amount
      else {
        minAmountMessage.style.display = "none";
        maxAmountMessage.style.display = "none";
        submitButton.disabled = false;
      }
    }

    // re-set form when trying to re-enter amount
    form.addEventListener("click", function(){
      minAmountMessage.style.display = "none";
      maxAmountMessage.style.display = "none";
      submitButton.disabled = false;
    });

    // redirect to the right URL with amount parameter only
    form.addEventListener("submit", function(e){
      e.preventDefault();
      var amount = amountInput.value;
      var requirePhoneNumber = requirePhoneNumberInput ? requirePhoneNumberInput.value : 0;

      // gift increase form needs to have parameters for FormAssembly form
      if (form.classList.contains("donate-widget-gift-form")) {
        var fromAmount = form.querySelector(".gift-from-amount").value;
        var campaignCode = form.querySelector(".campaign-code").value;

        window.location.href = form.action + "?tfa_242=" + amount + "&tfa_241=" + fromAmount + "&tfa_250=" + campaignCode;
      }
      else {
        let urlParams = "?amount=" + amount;

        if (requirePhoneNumber == 1) {
          urlParams = urlParams + "&requirePhoneNumber=1";
        }

        window.location.href = form.action + urlParams;
      }
    });
  }
}

function amountUpdate(radioField, amountField) {
  radioField.onchange = function() {
    amountField.value = radioField.value;
  }
}

document.querySelectorAll('a[href="#donation-widget"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
      e.preventDefault();
      
      const widget = document.getElementById('donation-widget');
      const offset = window.innerHeight / 2 - widget.offsetHeight / 2;
      
      window.scrollTo({
          top: widget.getBoundingClientRect().top + window.scrollY - offset,
          behavior: 'smooth'
      });
  });
});
