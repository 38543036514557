function goToNextPage(currentPage) {
    const queryParams = window.location.search;
    const nextPage = currentPage.next(".form-page");
    const nextPagePath = nextPage.data("path");
    const nextPageTitle = nextPage.find("h1.title").text();

    history.replaceState({}, "", nextPagePath + queryParams);

    // 'virtualPageView'
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": "virtual_page_view", // CONSTANT
        "virtual_page_url": nextPagePath + queryParams, // Virtual page view URL e.g. /donate/monthly/your-address/?amount=24
        "virtual_page_path": nextPagePath, // Virtual page path e.g. /donate/monthly/your-address/
        "virtual_page_title": nextPageTitle // Virtual page title e.g. Funnel Step 2: Your Address. Ensure all steps have a title.
    });

    // console.log(window.dataLayer);

    currentPage.hide();
    nextPage.show();
    window.scrollTo(0, 0);
}

function goToPreviousPage(currentPage) {
    const queryParams = window.location.search;
    const previousPage = currentPage.prev(".form-page");
    const previousPagePath = previousPage.data("path");
    const previousPageTitle = previousPage.find("h1.title").text();

    history.replaceState({}, "", previousPagePath + queryParams);

    // 'virtualPageView'
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": "virtual_page_view", // CONSTANT
        "virtual_page_url": previousPagePath + queryParams, // Virtual page view URL e.g. /donate/monthly/your-address/?amount=24
        "virtual_page_path": previousPagePath, // Virtual page path e.g. /donate/monthly/your-address/
        "virtual_page_title": previousPageTitle // Virtual page title e.g. Funnel Step 2: Your Address. Ensure all steps have a title.
    });

    // console.log(window.dataLayer);

    currentPage.hide();
    previousPage.show();
    window.scrollTo(0, 0);
}

function validatePage(currentPage) {
    const currentPageId = currentPage.attr("id");
    const currentPageTitle = currentPage.find("h1.title").text();

    let formValid = true;
    let firstInvalidField = null;
    let formErrors = [];

    let fields = currentPage.find("input");
    fields = $.merge(currentPage.find("select"), fields);
    fields.each(function() {
        const fieldRow = $(this).parents(".field-row");
        let errEl = fieldRow.find(".form-field-error");

        if ($(this).is(":invalid")) {
            $(this).attr("aria-invalid", "true");
            fieldRow.addClass("field-row--err");

            const labelText = $(this).siblings("label").text();

            let errMessage = "There's an error with this field";
            const validity = $(this)[0].validity;
            const fieldType = $(this)[0].type;

            if (validity.valueMissing) {
                errMessage = "Please fill in this field";
            }

            if (validity.typeMismatch && fieldType == "email") {
                errMessage = "Please enter a valid email address";
            }

            if (errEl.length !== 0) {
                errEl.text(errMessage)
            } else {
                const preErrEl = fieldRow.children(".field-hint").length !== 0 ? fieldRow.children(".field-hint") : fieldRow.children("label");
                preErrEl.after("<span class='form-field-error'>" + errMessage + "</span>");
            }

            if (!firstInvalidField) {
                firstInvalidField = fieldRow;
                $("html, body").animate({
                        scrollTop: fieldRow.offset().top - 164
                    },
                    500
                );
            }

            formErrors.push(labelText);

            formValid = false;
        }
    });

    if (formValid) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "form_valid",
            "form_id": currentPageId,
            "form_name": currentPageTitle,
            "form_location": "Body",
        });
    } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "form_invalid",
            "form_id": currentPageId,
            "form_name": currentPageTitle,
            "form_location": "Body",
            "form_errors": formErrors // An array of form field errors.  Please pass validations errors client and server side. Do not include any empty fields.
        });
    }

    // console.log(window.dataLayer);

    return formValid;
}

/**
 * Function For Display Error Message
 */
function showErrorMsg(PaymentType, errormsg) {
    $("#error-msg-" + PaymentType).hide();
    $("#error-msg-" + PaymentType).html(errormsg);
    $("#error-msg-" + PaymentType).show().delay(5000).fadeOut();
    $(".pay_submit").val("Try Again");
    $(".pay_submit").attr("disabled", false);
}

$(document).ready(function() {
    let params = new URLSearchParams(location.search);
    let step = params.get('step');
    if (step != '' && step != null) {
        $("#donateFormPage1").hide();
        $("#donateFormPage2").hide();
        $("#donateFormPage3").hide();
        $("#donateFormPage4").hide();
        $("#donateFormPage5").hide();
        $("#donateFormPage" + step).show();
        $("#postcodeLookup").hide();
        $("#manualAddress").show();
        userPreferences();
    }

    if ($("#webFormSession").val() == 'Yes') {
        $("#postcodeLookup").hide();
        $("#manualAddress").show();
    }
});

/**
 * Set Session variables
 */
function setSession() {
    $.ajax({
        'type': 'post',
        'url': '/actions/capukpayment/payment/set-session',
        'data': $("#donateForm").serialize()
    }).done(function(response) {
        console.log(response);
    }).fail(function(error) {
        console.log("API Call Failed!");
    });
}

/**
 * Findock Payment API Call
 */
function paymentGateway(PaymentType) {
    // Uppercase the first char of firstname and lastname fields before call (https://trello.com/c/30nE9t8D/467-donation-forms-name-fields-api-update)
    let firstNameVal = $("#firstName").val();
    $("#firstName").val(firstNameVal.charAt(0).toUpperCase() + firstNameVal.slice(1));

    let lastNameVal = $("#lastName").val();
    $("#lastName").val(lastNameVal.charAt(0).toUpperCase() + lastNameVal.slice(1));

    // Format the postcode (https://trello.com/c/qyigIMNv/530-postcode-format)
    let postcodeVal = $("#postcode").val();
    postcodeVal = postcodeVal.replace(" ", "");
    postcodeVal = postcodeVal.toUpperCase();
    let postcodeParts = postcodeVal.match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/);
    if(postcodeParts) {
        postcodeParts.shift();
        $("#postcode").val(postcodeParts.join(" "));
    }

    $.ajax({
        type: "get",
        url: '',
        data: $("#donateForm").serialize(),
        success: function(data) {
            var res = $.parseJSON(data);

            if (res["status"] != 1) {
                window.location.href = res["res_redirect_url"];
            } else {
                showErrorMsg(PaymentType, "something went wrong please try again!");
            }
        },
        error: function(XMLHttpRequest, textStatus) {
            showErrorMsg(PaymentType, "something went wrong please try again!");
        }
    });
}
/**
 * get consent data held for this person from salesforce
 */
function userPreferences() {
    // Check we have all 3 pieces of name and email data before building request
    let firstname = $("#firstName").val(),
        lastName = $("#lastName").val(),
        email = $("#email").val();

    if (!firstname || !lastName || !email) {
        // Return early if we're missing any
        return false;
    }
    $.ajax({
        type: "post",
        url: '/actions/capukpayment/payment/user-preferences',
        data: {
            email: email,
            firstname: firstname,
            lastName: lastName,
        },
        success: function(data) {
            const preferences_Msg = [];
            if (data.phone === 'Opt-In') {
                $("#contactPreferences__phone").prop('checked', true);
                $("#contactPreferences__phone").prop("type", "hidden");
                $("#contactPreferences__phone-text").hide();
                preferences_Msg.push('phone');
            }
            if (data.sms === 'Opt-In') {
                $("#contactPreferences__textMessage").prop('checked', true);
                $("#contactPreferences__textMessage").prop("type", "hidden");
                $("#contactPreferences__textMessage-text").hide();
                preferences_Msg.push('text');
            }
            if (data.letter === 'Opt-In') {
                $("#contactPreferences__letter").prop('checked', true);
                $("#contactPreferences__letter").prop("type", "hidden");
                $("#contactPreferences__letter-text").hide();
                preferences_Msg.push('letter');
            }
            if (data.email === 'Opt-In') {
                $("#contactPreferences__email").prop('checked', true);
                $("#contactPreferences__email").prop("type", "hidden");
                $("#contactPreferences__email-text").hide();
                preferences_Msg.push('email');
            }
            if (preferences_Msg.length) {
                const resultMsg = preferences_Msg.map(x => x).join(", ");
                $("#contactPreferencesMessage").html("You've already opted in to " + resultMsg.replace(/,(?=[^,]*$)/, ' and ') + '. To opt out please call <a href="tel: 01274760720">01274 760720</a>.');
            }
        },
        error: function(XMLHttpRequest, textStatus) {
            console.log("Status: " + textStatus);
        }
    });
}

$(".form--next-page").on("click", function() {
    const currentPage = $(this).parents(".form-page");
    if (validatePage(currentPage)) {

        if ($(this).hasClass("pay_submit")) {
            $(this).val("Loading...");
            $(this).attr("disabled", true);
            grecaptcha.ready(function() {
                grecaptcha.execute("6LcyGLofAAAAAM_ZpiDxOje82p0ZrQWMZbA3hhBi", { action: "submit" }).then(function(token) {

                    var formDataSerializedArray = $("#donateForm").serializeArray();
                    var donationType = formDataSerializedArray.find(({ name }) => name === "paymentType");
                    donationType = donationType.value;
                    var giftAidStatus = formDataSerializedArray.find(({ name }) => name === "giftAidStatus");
                    giftAidStatus = giftAidStatus.value;

                    var urlParams = new URLSearchParams(window.location.search);
                    var amount = urlParams.get("amount");
                    var promotion = urlParams.get("promotion") || "";

                    if (donationType == "monthly") {
                        var paymentType = "Direct Debit";
                        var itemId = "011";
                        var itemName = "life-changer-new";
                    } else {
                        var itemId = "001";
                        var itemName = "one-off donation";

                        var paymentMethod = formDataSerializedArray.find(({ name }) => name === "paymentMethod");

                        if (paymentMethod.value == "applePay") {
                            var paymentType = "Apple Pay";
                        } else if (paymentMethod.value == "googlePay") {
                            var paymentType = "Google Pay";
                        } else if (paymentMethod.value == "paypal") {
                            var paymentType = "Paypal"
                        } else {
                            var paymentType = "Credit or debit card";
                        }
                    }

                    window.dataLayer = window.dataLayer || [];
                    // window.dataLayer.push({ ecommerce: null });     // Clear the previous ecommerce object.
                    window.dataLayer.push({
                        'event': 'add_payment_info', // CONSTANT
                        'ecommerce': {
                            'currency': 'GBP', // CONSTANT
                            'value': amount, // no commas on values above 1000, no currency symbols
                            'coupon': promotion, // Promotion
                            'payment_type': paymentType, // Payment method e.g. Credit Card / Debit Card / Direct Debit / Paypal / Apple Pay / Google Pay
                            'items': [{
                                'item_id': itemId, // 001 / 011 / 012
                                'item_name': itemName, // One-off donation / life-changer-new / life-changer-increase
                                'item_category': 'Donations', // Category e.g Donations
                                'currency': 'GBP', // CONSTANT
                                'price': amount, // E.g. 50 no commas on values above 1000, no currency symbols
                                'quantity': 1 // CONSTANT
                            }]
                        }
                    });

                    // console.log(window.dataLayer);

                    // Put some values into localstorage so we can retrieve them on the other side
                    // of the donation, on the success URL, and then send them to a new datalayer event
                    localStorage.setItem("CAP_donation_amount", amount);
                    localStorage.setItem("CAP_donation_itemId", itemId);
                    localStorage.setItem("CAP_donation_giftAidStatus", giftAidStatus);
                });
                setSession();
                paymentGateway($("#PaymentType").val());
            }).catch(function(e) {
                showErrorMsg($("#PaymentType").val(), "recaptcha missing please try again!");
            });
            return;
        }
        if ($(this).hasClass("monthly-debit-date")) {
            debitDateCheck($("#firstPayment__month option:selected").text());
        }
        if ($(this).hasClass("check-user-preferences")) {
            userPreferences();
        }

        goToNextPage(currentPage);
    }
});

$(".form--previous-page").on("click", function() {
    goToPreviousPage($(this).parents(".form-page"));
});

// Switch from postcode lookup to manual address entry
$("#postcodeLookupToManual").on("click", function() {
    $("#postcodeLookup").hide();
    $("#manualAddress").show();
});

// Expand conditional sub questions
$(".form-choice--has-sub-questions > input, .form-choice--has-no-sub-questions > input").on("change", function() {
    // Close others
    if (this.type == "radio") {
        $(this).parents(".form-choices").find(".conditional-panel").slideUp("fast");
    }

    if (this.checked) {
        $(this).parent().next(".conditional-panel").slideDown("fast");
    } else {
        $(this).parent().next(".conditional-panel").slideUp("fast");
    }
});

// Update postcode from manual entry
$("#postcodeManual").change(function() {
    $('#postcode').val($(this).val());
});

// Checking Direct debit future +10 days
function debitDateCheck(chosenMonthYears) {

    var chosenMonthYearParts = chosenMonthYears.split(' ');
    const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
    const startDateDaysOfMonth = [1, 8, 15, 28];
    const startDateOpts = startDateDaysOfMonth.map(date => new Date(chosenMonthYearParts[1], months.indexOf(chosenMonthYearParts[0].toLowerCase()), date));
    var cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() + 10);
    var availableDates = startDateOpts.filter(date => date >= cutoffDate);
    const transformedAvailableDates = availableDates.map(date => date.getDate());

    $("#firstPayment__day").html('');
    var str = "";
    startDateDaysOfMonth.forEach(function(value, i) {
        var disabled = "disabled";
        if (transformedAvailableDates.indexOf(value) >= 0) {
            disabled = "";
        }
        let newValue = '0' + value;
        if (i == 0) {
            str += "<option value='" + newValue + "' " + disabled + " >1st</option>";
        } else if (i == 1) {
            str += "<option value='" + newValue + "' " + disabled + " >8th</option>";
        } else {
            str += "<option value='" + value + "'" + disabled + " >" + value + "th</option>";
        }
    });
    $("#firstPayment__day").html(str);
}
$("#firstPayment__month").on("change", function() {
    debitDateCheck($("#firstPayment__month option:selected").text());
});

// loqate address Search Start
function showError(message) {
    var error = document.getElementById("errorMessage");
    error.innerText = message;
    error.style.display = "block";

    setTimeout(function() {
        error.style.display = "none";
    }, 10000)
}

function enterSearch(e) {
    if (e.keyCode == 13) {
        findAddress();
    }
}

function ValidatePostcode(postcode) {
    if (/^[A-Za-z]{1,2}[0-9A-Za-z]{1,2}[ ]?[0-9]{0,1}[A-Za-z]{2}$/.test(postcode)) {
        return true;
    }
}

function findAddressInvalidDataLayer() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": "form_invalid",
        "form_id": "postcodeLookup",
        "form_name": "Monthly donation postcode lookup",
        "form_location": "Body",
        "form_errors": ["Postcode"]
    });

    // console.log(window.dataLayer);
}

function findAddress(SecondFind) {
    var Text = document.getElementById("postcode").value;

    // Error states
    if (Text == "" || !ValidatePostcode(Text)) {
        findAddressInvalidDataLayer();

        if (Text === "") {
            showError("Please enter an address");
        }

        if (!ValidatePostcode(Text)) {
            showError("Please enter a valid UK postcode");
        }

        return
    }

    var Container = "";

    if (SecondFind !== undefined) {
        Container = SecondFind;
    }

    $.ajax({
        'type': 'get',
        'contentType': 'application/x-www-form-urlencoded; charset=UTF-8',
        'cache': false,
        'url': '/actions/capukpayment/payment/get-loqate?text=' + Text + '&con=' + Container,
        'dataType': 'json'
    }).done(function(response) {
        if (response.Items.length == 1 && typeof(response.Items[0].Error) != "undefined") {
            showError(response.Items[0].Description);
            findAddressInvalidDataLayer();
        } else {
            if (response.Items.length == 0)
                showError("Sorry, there were no results");
            else {
                if (response.Items.length == 1) {
                    if (response.Items[0].Type === "Postcode") {
                        findAddress(response.Items[0].Id);
                    }
                }
                var resultBox = document.getElementById("result");

                if (resultBox.childNodes.length > 0) {
                    var selectBox = document.getElementById("mySelect");
                    selectBox.parentNode.removeChild(selectBox)
                }

                var resultArea = document.getElementById("result");
                var list = document.createElement("select");
                list.id = "selectList";
                list.setAttribute("id", "mySelect");
                resultArea.appendChild(list);

                var defaultOption = document.createElement("option");
                defaultOption.text = "Select Address";
                defaultOption.setAttribute("value", "");
                defaultOption.setAttribute("selected", "selected");
                list.appendChild(defaultOption);

                for (var i = 0; i < response.Items.length; i++) {
                    var option = document.createElement("option");
                    option.setAttribute("value", response.Items[i].Id)
                    option.text = response.Items[i].Text + " " + response.Items[i].Description;
                    option.setAttribute("class", response.Items[i].Type)

                    list.appendChild(option);
                }
                selectAddress();
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                "event": "form_valid",
                "form_id": "postcodeLookup",
                "form_name": "Monthly donation postcode lookup",
                "form_location": "Body"
            });

            // console.log(window.dataLayer);
        }
    }).fail(function(error) {
        console.log("loqate API Call Failed!")
    });
};

function selectAddress() {
    var resultList = document.getElementById("result");

    if (resultList.childNodes.length > 0) {
        var elem = document.getElementById("mySelect");

        // IE fix
        elem.onchange = function(e) {

            var target = e.target[e.target.selectedIndex];
            // console.log(target.className);
            if (target.text === "Select Address") {
                return;
            }

            if (target.className === "Address") {
                $.ajax({
                    'type': 'get',
                    'contentType': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'cache': false,
                    'url': '/actions/capukpayment/payment/get-address?targetid=' + target.value,
                    'dataType': 'json'
                }).done(function(response) {
                    if (response.Items.length == 1 && typeof(response.Items[0].Error) != "undefined") {
                        showError(response.Items[0].Description);
                    } else {
                        if (response.Items.length == 0) {
                            showError("Sorry there were no results");
                        } else {
                            var res = response.Items[0];
                            // console.log(res);
                            $("#postcodeLookup").hide();
                            $("#manualAddress").show();
                            $('#addressLine1').val(res.Line1);
                            $('#addressLine2').val(res.Line2);
                            $('#addressLine3').val(res.ProvinceName);
                            $('#addressLine4').val(res.CountryName);
                            $('#postcodeManual').val(document.getElementById("postcode").value);
                        }
                    }

                }).fail(function(error) {
                    console.log("loqate API Call Failed!")
                });
            } else {
                findAddress(target.value)
            }
        };
    }
};
// loqate address Search End