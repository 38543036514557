$(".list-filter-trigger").on("click", function() {
  var popUp = $(this).siblings("#list-filter-pop-up");
  showPopup(popUp);
});

$(".list-filter-off").on("click", function() {
  var popUp = $(this).parents("#list-filter-pop-up");
  hidePopup(popUp);
});

function showPopup(popUpEl) {
  popUpEl.addClass("active");
}

function hidePopup(popUpEl) {
  popUpEl.removeClass("active");
}