$(".video__button").on("click", function() {
  var $this = $(this);
  var parent = $this.parents(".video");
  var videoEl = $this.siblings(".video__asset");

  parent.addClass("video__playing");
  // start playing youtube or vimeo video
  videoEl[0].src += "?autoplay=1";
});


