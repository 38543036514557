var postcodeForms = document.getElementsByClassName("get-help-postcode-form");
var postcodeRegex = /[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? ?[0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}/;

if (postcodeForms != 'undefined' && postcodeForms != null) {
  for (var i = 0; i < postcodeForms.length; i++) {
    var postcodeForm = postcodeForms[i];
    var postcodeInput = postcodeForm.querySelector(".postcode-input");
    var submitBtn = postcodeForm.querySelector(".postcode-submit");

    postcodeFormat(postcodeInput, submitBtn);
  }
}

function postcodeFormat(inputField, submitBtn) {
  inputField.onkeyup = function() {
    submitBtn.disabled = false;
    this.parentNode.parentNode.classList.remove("field-error");
    this.parentNode.previousElementSibling.style.display = "none";
  }

  inputField.onchange = function() {
    // make the value to uppercase
    this.value = this.value.toUpperCase();
    // insert space after first part
    this.value = this.value.replace(/(\S*)\s*(\d)/, "$1 $2");

    if (this.value.match(postcodeRegex)) {
      // console.log("postcode correct");
      this.parentNode.parentNode.classList.remove("field-error");
      this.parentNode.previousElementSibling.style.display = "none";
      submitBtn.disabled = false;
    } else {
      // console.log("postcode incorrect");
      this.parentNode.parentNode.classList.add("field-error");
      this.parentNode.previousElementSibling.style.display = "block";
      submitBtn.disabled = true;
    }
  }
}