//-----------------------------//
// sticky sitewide appeal banner
//-----------------------------//

var header = $(".site-header"),
    banner = $(".sitewide-appeal-banner--fixed"),
    prevScrollPos = 0;

$(window).on("scroll resize load", (function() {
  var headerHeight = header.outerHeight(),
      stickyPoint = headerHeight + banner.outerHeight(),
      nowScrollPos = $(window).scrollTop();

  // if top
  if (nowScrollPos == 0) {
    banner.removeClass("sticky hide");
  }
  // if scroll up
  else if (nowScrollPos < prevScrollPos) {
    if (nowScrollPos < headerHeight) {
      banner.removeClass("sticky");
    }
    else {
      banner.removeClass("hide");
    }
  }
  // if scroll down
  else {
    banner.removeClass("hide");

    if (nowScrollPos >= headerHeight) {
      banner.addClass("sticky");
    }
  }

  prevScrollPos = nowScrollPos;
}));