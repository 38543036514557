//is on viewport
$.fn.isOnScreen = function(){
  var win = $(window);
  var viewport = {
    top : win.scrollTop(),
    left : win.scrollLeft()
  };
  viewport.right = viewport.left + win.width();
  viewport.bottom = viewport.top + win.height();
  var bounds = this.offset();
  bounds.right = bounds.left + this.outerWidth();
  bounds.bottom = bounds.top + this.outerHeight();
  return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
};

var animation = true; // initiate animation

// animate on loading/scrolling if in view
$(window).on('load scroll', function() {
  if ($(window).width() < 640) {
    animateAmount($('.amount-animation--mobile'));
  } else {
    animateAmount($('.amount-animation'));
  }
});

function animateAmount(el) {
  el.each(function() {
    if ($(this).isOnScreen()) {
      if (animation) {
        $(this).prop('Counter',0).animate({
          Counter: $(this).text()
        }, {
            duration: 4000,
            easing: 'swing',
            step: function (now) {
              // $(this).text(Math.ceil(now));
              $(this).text(Math.ceil(now).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
            }
        });
        animation = false; // cancel animation, so it animates only once
      }
    }
  });
}